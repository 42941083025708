

.AChart select {
  background: none;
  border: 1px solid #dedede;
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
}

.AChart h4 {
  text-align: center;
  margin-top: 50px;
}

.contactUs {
  width: 100%;
  padding: 50px 0px 50px;
  background: white;
}
.contactInner {
  width: 80%;
  max-width: 660px;
  margin: 0 auto;
}

.contactInner input {
  width: 100%;
  background: none;
  border: 1px solid #d3d9d9;
  border-radius: 5px;
  color: black;
}

.contactInner textarea {
  width: 100%;
  background: none;
  border: 1px solid #d3d9d9;
  border-radius: 5px;
  color: black;
}

.contactInner input:focus,
.contactInner textarea:focus {
  background: none;
  color: black;
}

.captchaWrap {
  width: 302px;
  margin: auto;
  background: white;
  padding-bottom: 30px;
}

input[type="submit"]:hover {
  background: #eaeaea;
  color: black;
  box-shadow: 2px 2px 5px 1px #eaeaea;
}

.contactInner h1 {
  font-size: 32px;
  text-align: center;
}

p#sendErr {
  color: red;
  width: 100%;
  text-align: center;
}

p#sucMessage {
  top: 0px;
  left: 0px;
  line-height: 48px;
  position: fixed;
  width: 100%;
  height: 50px;
  text-align: center;
  background: #e4ffbb;
  z-index: 999;
}

@media only screen and (max-width: 768px) {
  p#sucMessage {
    top: 0px;
    z-index: 9;
    height: 48px;
    line-height: 48px;
  }
}

