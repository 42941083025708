.AChart select {
  background: none;
  border: 1px solid #dedede;
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
}

.AChart h4 {
  text-align: center;
  margin-top: 50px;
}
